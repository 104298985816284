import { jwtDecode } from "jwt-decode";

const getTokenFromLocalStorage = () => {
  try {
    let tokenResult = localStorage.getItem("okta-token-storage");
    if (tokenResult) {
      return JSON.parse(tokenResult);
    }
  } catch (error) {
    console.error("Error retrieving token:", error);
  }
  return null;
};

export const getAPIHeader = () => {
  let header = {
    "Content-Type": "application/json",
  };
  try {
    let token = getTokenFromLocalStorage();
    if (token && token.accessToken) {
      header["authorization"] = `Bearer ${token.accessToken}`;
    }
    return header;
  } catch (error) {
    return header;
  }
};

export const parseJWTDetail = (type) => {
  try {
    let dataID = "";
    let token = getTokenFromLocalStorage();
    if (token && token.accessToken) {
      const data = jwtDecode(token.accessToken);
      if (type === "account") {
        dataID = data["https://zero13.net/userdata"]["accountid"];
      } else if (type === "group") {
        dataID = data["https://zero13.net/userdata"]["groupid"];
      } else {
        dataID = data["https://zero13.net/userdata"]["participantid"];
      }
    }
    return dataID;
  } catch (error) {
    return "";
  }
};

export const getUsername = () => {
  try {
    let token = getTokenFromLocalStorage();
    if (token) {
      return token["tokenDetails"]["https://zero13.net/userdata"]["displayname"];
    }
    return "";
  } catch (error) {
    return "";
  }
};

/**
 * Check if the user has access to Inventory features based on roles in JWT token.
 * @returns {boolean} True if the user has access, false otherwise.
 */
export const hasUserAccess = (requiredRoles = []) => {
  try {
    const tokenResult = localStorage.getItem("okta-token-storage");
    if (!tokenResult) return false;

    const {
      tokenDetails: { exp, "https://zero13.net/roles": roles = [] } = {},
    } = JSON.parse(tokenResult);

    if (exp < Math.floor(Date.now() / 1000)) {
      return false; // Token expired
    }

    return requiredRoles.some((role) => roles.includes(role));
  } catch (error) {
    console.error("Error checking user access:", error);
    return false;
  }
};

export const getRegistryFromToken = () => {
  try {
    let tokenResult = getTokenFromLocalStorage();
    if (tokenResult && tokenResult.tokenDetails) {
      return tokenResult.tokenDetails["https://zero13.net/userdata"]["registry"] || "";
    }
    return "";
  } catch (error) {
    console.error("Error retrieving registry:", error);
    return "";
  }
};

export const updatePage = (prevPage, totalPages, type) => {
  if (type === "next" && prevPage < totalPages - 1) return prevPage + 1;
  if (type === "prev" && prevPage > 0) return prevPage - 1;
  return prevPage;
};

