import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../shared/Layout";
import Table from "../widgets/Table";
import { docColumns } from "../data/DocumentsTableData";
import Pagination from "../widgets/Pagination";
import PageTitle from "../widgets/PageTitle";
import { getDocuments, getDocumentTypes } from "../services/DocumentService";
import { Bars } from "react-loader-spinner";
import { useAtom } from "jotai";
import { accountsAtom, initLoadingAtom } from "../helper/store";
import TypeFilter from "../components/Filters/TypeFilter";
import RangeFilter from "../components/Filters/RangeFilter";
import moment from "moment";
import DocumentSort from "../components/Sorts/DocumentSort";
import { getExchangeConfig } from "../helper/platformConfig";
import { updatePage } from "../services/CommonService";
import { getCertificateDocuments } from "../services/InventoryService";
import certificateDocumentModel from "../models/CertificateDocumentModel";

const Documents = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [accounts] = useAtom(accountsAtom);
  const [initLoading] = useAtom(initLoadingAtom);

  const [documentTypes, setDocumentTypes] = useState([]);
  const [currentType, setCurrentType] = useState("all");
  const [sortType, setSortType] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  // Extract retirement-request-id from the URL
  const queryParams = new URLSearchParams(location.search);
  const retirementRequestId = queryParams.get("retirement-request-id");

  useEffect(() => {
    getDocumentTypes().then((result) => {
      if (result.status) {
        setDocumentTypes([...result.data, { label: "Certificate", value: "certificate" }]);
      } else {
        setDocumentTypes([{ label: "Certificate", value: "certificate" }]);
      }
      setCurrentType("all");
    });
  }, []);

  useEffect(() => {
    if (!initLoading) {
      getDocumentAPI(currentPage, currentType, dateFrom, dateTo, sortType, retirementRequestId);
    }
  }, [currentPage, currentType, initLoading, dateFrom, dateTo, sortType, retirementRequestId]);

  const getDocumentAPI = async (page, type, dateFrom, dateTo, sortBy, retirementRequestId) => {
    setLoading(true);
    setErrorMsg("");
    setData([]);

    try {
      const docResult = await getDocuments({
        page,
        type,
        accounts,
        dateStart: dateFrom,
        dateEnd: dateTo,
        sortBy,
      });

      const certResult = await getCertificateDocuments(
        retirementRequestId, 
        sortBy ?? { key: "date-created", order: "desc" },
        0, 
        10, 
        dateFrom, 
        dateTo
      );      

      const normalDocs = docResult.status ? docResult.data : [];
      const certDocs = certResult.status ? certificateDocumentModel.parseList(certResult.data) : [];
      const combinedData = [...normalDocs, ...certDocs];

      if (combinedData.length === 0) {
        setErrorMsg("No Data Available");
      }

      setData(combinedData);

      if (docResult.status) {
        const rowsPerPage = 10;
        setTotalPages(Math.ceil(docResult.totalRows / rowsPerPage));
      }
    } catch (error) {
      console.error("Failed to fetch documents:", error);
      setErrorMsg("Failed to fetch documents.");
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (pagingType) => {
    if (loading) return;
    setCurrentPage((prevPage) => updatePage(prevPage, totalPages, pagingType));
  };

  const onFilterChange = (filterType, filterValue) => {
    if (loading) return;
    setCurrentPage(0);

    if (filterType === "range") {
      if (!filterValue || !filterValue[0]) {
        setDateFrom(null);
        setDateTo(null);
      } else {
        setDateFrom(moment(filterValue[0]).format("YYYY-MM-DD"));
        setDateTo(moment(filterValue[1]).format("YYYY-MM-DD"));
      }
    } else {
      setCurrentType(filterValue.value);
    }
  };

  const onSortChange = (_, selectedOption) => {
    if (loading) return;
    setCurrentPage(0);
    setSortType(selectedOption.value);
  };

  return (
    <Layout className="space-y-6">
      <div className="relative lg:flex xl:flex items-center justify-between gap-6">
        <PageTitle title={getExchangeConfig().sidebarLabels[1]} level={1} />
        <div className="relative flex items-center xl:justify-end lg:justify-end gap-6 mt-10 xl:mt-0 lg:mt-0">
          <RangeFilter onFilterchange={onFilterChange} />
          <TypeFilter data={documentTypes} onFilterchange={onFilterChange} />
          <DocumentSort
            data={[
              { label: "None", value: "none" },
              { label: "Description", value: "name" },
              { label: "Type", value: "type" },
              { label: "Date", value: "date-published" },
            ]}
            onFilterchange={onSortChange}
          />
        </div>
      </div>

      {loading && (
        <div className="text-center py-10 flex justify-center">
          <Bars height="80" width="80" color="rgb(78,183,63)" ariaLabel="bars-loading" />
        </div>
      )}

      {!loading && data.length > 0 && <Table columns={docColumns} data={data} />}

      {!loading && data.length === 0 && <div className="text-center py-10">{errorMsg}</div>}

      {totalPages > 0 && (
        <Pagination currentPage={currentPage} totalPage={totalPages} onPageChange={onPageChange} />
      )}
    </Layout>
  );
};

export default Documents;
