import Auth0Lock from "auth0-lock";
import Logo from "../assets/images/secdex-favicon.png";

export const auth0Lock = new Auth0Lock(
  window.env.REACT_APP_OKTA_CLIENT_ID,
  window.env.REACT_APP_OKTA_HOST,
  {
    auth: {
      params: {
        scope: "openid profile email",
      },
      redirect: false,
    },
    closable: false,
    container: "hiw-login-container",
    theme: {
      logo: Logo,
      primaryColor: "#127d45",
    },
    languageDictionary: {
      title: "SECDEX Portal",
    },
    allowSignUp: false,
  }
);
