import { createColumnHelper } from "@tanstack/react-table";
import React, { useState } from "react";
import IconExternalLink from "../assets/icons/IconExternalLink";
import IconCreateDoc from "../assets/icons/IconCreateDoc";
import { Tooltip } from "react-tooltip";
import { createCertificate } from "../services/InventoryService";
import { Bars } from "react-loader-spinner";

const columnHelper = createColumnHelper();

const StatusCell = ({ statusValue, requestId, refreshTable }) => {
  const [isCreating, setIsCreating] = useState(false);
  const tooltipId = `tooltip-status-${requestId}`;

  const handleCreateCertificate = async (e) => {
    e.stopPropagation(); // Prevent row click
    if (isCreating) return; // Prevent multiple clicks
    setIsCreating(true);

    try {
      const { status, message } = await createCertificate(requestId);
      if (!status) {
        console.error("Failed to create certificate:", message);
        alert(`Error creating certificate: ${message}`);
        setIsCreating(false);
        return;
      }

      refreshTable(); // Refresh table after certificate creation
    } catch (error) {
      console.error("Error creating certificate:", error);
      alert("An unexpected error occurred while creating the certificate.");
    } finally {
      setIsCreating(false);
    }
  };

  const handleGoToDocuments = (e) => {
    e.stopPropagation();
    window.location.href = `/documents?retirement-request-id=${requestId}`;
  };

  if (statusValue.toLowerCase() === "certified") {
    return (
      <div className="flex items-center space-x-2">
        <span>{statusValue}</span>
        <button onClick={handleGoToDocuments} data-tooltip-id={tooltipId} className="flex items-center">
          <IconExternalLink className="size-6" strokeWidth={2} />
        </button>
        <Tooltip id={tooltipId} place="top" variant="light">Go to Certificate</Tooltip>
      </div>
    );
  }

  // Show "Creating" status and loader during certificate creation
  if (statusValue.toLowerCase() === "retired") {
    return (
      <div className="flex items-center space-x-2">
        <span>{isCreating ? "Creating..." : statusValue}</span>
        {isCreating ? (
          <Bars height="24" width="24" color="rgb(78,183,63)" ariaLabel="bars-loading" />
        ) : (
          <button type="button" onClick={handleCreateCertificate} data-tooltip-id={tooltipId} className="flex items-center" disabled={isCreating}>
            <IconCreateDoc className="size-6" strokeWidth={2} />
          </button>
        )}
        <Tooltip id={tooltipId} place="top" variant="light">
          {isCreating ? "Creating Certificate..." : "Create Certificate"}
        </Tooltip>
      </div>
    );
  }

  return statusValue;
};

export const retirementRequestColumns = (refreshTable) => [
  columnHelper.accessor("id", {
    header: () => "ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("date", {
    header: () => "Date",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("time", {
    header: () => "Time",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetCode", {
    header: () => "Asset Code",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registry", {
    header: () => "Registry",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("quantity", {
    header: () => "Quantity",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("holder", {
    header: () => "Holder",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("burnUrl", {
    header: () => "Burn Transaction",
    cell: ({ row }) => {
      const url = row.original.burnUrl || "#";
      const tooltipId = `tooltip-burn-${row.original.id}`;

      return (
        <div className="flex items-center">
          <a href={url} target="_blank" rel="noopener noreferrer" className="flex items-center" data-tooltip-id={tooltipId} onClick={(e) => e.stopPropagation()}>
            <IconExternalLink className="size-6" strokeWidth={2} />
          </a>
          <Tooltip id={tooltipId} place="top" variant="light">Open Burn URL</Tooltip>
        </div>
      );
    },
  }),
  columnHelper.accessor("status", {
    header: () => "Status",
    cell: ({ row, getValue }) => (
      <StatusCell statusValue={getValue()} requestId={row.original.id} refreshTable={refreshTable} />
    ),
  }),
];