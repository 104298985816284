import React, { useState, useEffect } from "react";
import Button from "../widgets/Button";
import IconExternalLink from "../assets/icons/IconExternalLink";
import EditInventoryConfirmationModal from "./EditInventoryConfirmationModal";

const EditInventoryModal = ({ isOpen, onClose, inventoryItem, onSave }) => {
  const [formData, setFormData] = useState({
    registryProject: "",
    registryRecord: "",
    startId: "",
    endId: "",
    assetName: "",
    ...inventoryItem,
  });

  const [error, setError] = useState(null);
  const [isConfirmationStep, setIsConfirmationStep] = useState(false);
  const [editedFields, setEditedFields] = useState({});
  const [modifiedFields, setModifiedFields] = useState({});

  useEffect(() => {
    if (inventoryItem) {
      setFormData({
        registryProject: inventoryItem.registryProject || "",
        registryRecord: inventoryItem.registryRecord || "",
        startId: inventoryItem.startId || "",
        endId: inventoryItem.endId || "",
        assetName: inventoryItem.assetName || "",
        ...inventoryItem,
      });
      setEditedFields({});
      setModifiedFields({});
    }
  }, [inventoryItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setEditedFields((prev) => ({
      ...prev,
      [name]: value !== inventoryItem[name] ? value : undefined,
    }));
    setModifiedFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (isNaN(formData.startId)) {
      setError("Start ID must be numeric.");
      return;
    }

    if (isNaN(formData.endId)) {
      setError("End ID must be numeric.");
      return;
    }

    if (Number(formData.startId) >= Number(formData.endId)) {
      setError("Start ID must be smaller than End ID.");
      return;
    }

    setError(null);
    setIsConfirmationStep(true);
  };

  const handleConfirmSave = () => {
    onSave(formData);
    setIsConfirmationStep(false);
    onClose();
  };

  const hasNewEdits = Object.keys(modifiedFields).some(
    (key) => modifiedFields[key] !== inventoryItem[key]
  );

  const isSaveDisabled = !hasNewEdits;

  const trancheDataKeys = Object.keys(formData).filter(
    (key) =>
      !["registryProject", "registryRecord", "startId", "endId", "assetName"].includes(key)
  );

  if (!isOpen && !isConfirmationStep) return null;

  return (
    <>
      {!isConfirmationStep ? (
        <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div
            className="modal-content bg-white rounded-lg shadow-xl p-6 w-[85vw] max-w-2xl overflow-y-auto"
            style={{
              maxHeight: "90vh",
              height: "auto",
            }}
          >
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Edit Inventory Item</h2>

            {/* Display Tranche Data */}
            <div className="space-y-3 mb-4">
              {trancheDataKeys.map((key, index) => (
                <div
                  key={key}
                  className={`flex justify-between items-center pb-2 ${
                    index < trancheDataKeys.length - 1 ? "border-b border-cD9" : ""
                  }`}
                >
                  <span className="text-base font-bold text-gray-800 capitalize">
                    {key.replace(/([A-Z])/g, " $1")}
                  </span>
                  {key === "creationUrl" ? (
                    <a
                      href={formData[key] || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-gray-500"
                    >
                      <IconExternalLink className="size-5" strokeWidth={2} />
                    </a>
                  ) : (
                    <span className="text-base text-gray-600">{formData[key] || "-"}</span>
                  )}
                </div>
              ))}
            </div>

            {/* Input Fields */}
            <div className="space-y-5">
              <label className="block">
                <span className="text-base font-bold text-gray-800">Registry Project</span>
                <input
                  name="registryProject"
                  type="text"
                  value={formData.registryProject}
                  onChange={handleChange}
                  className="mt-1 block w-full h-10 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-base p-2"
                />
              </label>

              <label className="block">
                <span className="text-base font-bold text-gray-800">Registry Record</span>
                <input
                  name="registryRecord"
                  type="text"
                  value={formData.registryRecord}
                  onChange={handleChange}
                  className="mt-1 block w-full h-10 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-base p-2"
                />
              </label>

              <label className="block">
                <span className="text-base font-bold text-gray-800">Start ID</span>
                <input
                  name="startId"
                  type="text"
                  value={formData.startId}
                  onChange={handleChange}
                  className="mt-1 block w-full h-10 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-base p-2"
                />
              </label>

              <label className="block">
                <span className="text-base font-bold text-gray-800">End ID</span>
                <input
                  name="endId"
                  type="text"
                  value={formData.endId}
                  onChange={handleChange}
                  className="mt-1 block w-full h-10 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-base p-2"
                />
              </label>

              <label className="block">
                <span className="text-base font-bold text-gray-800">Asset Name</span>
                <input
                  name="assetName"
                  type="text"
                  value={formData.assetName}
                  onChange={handleChange}
                  className="mt-1 block w-full h-10 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-base p-2"
                />
              </label>

              {error && <p className="text-red-500 text-sm">{error}</p>}
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-4 mt-6">
              <Button
                title="Cancel"
                onClick={onClose}
                className="bg-gray-300 text-gray-800 hover:bg-gray-400 py-2 px-6 rounded-md"
              />
              <Button
                title="Save"
                onClick={handleSave}
                className={`bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md ${isSaveDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                isDisabled={isSaveDisabled}
              />
            </div>
          </div>
        </div>
      ) : (
        <EditInventoryConfirmationModal
          isOpen={isConfirmationStep}
          editedFields={modifiedFields}
          onConfirm={handleConfirmSave}
          onCancel={() => setIsConfirmationStep(false)}
        />
      )}
    </>
  );
};

export default EditInventoryModal;