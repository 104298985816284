import moment from "moment";

const retirementRequestModel = {
  parseList(data) {
    return data.map(item => ({
      id: item.id,
      date: moment(item["date-created"]).format("D MMM YYYY"),
      time: moment(item["date-created"]).format("HH:mm:ss"),
      assetCode: item["asset-code"] || "",
      registry: item.registry || "",
      quantity: item.quantity || 0,
      holder: item.holder || "",
      burnUrl: item["burn-transaction"] || "",
      status: item.status || "",
    }));
  },
};

export default retirementRequestModel;