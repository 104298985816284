import { createColumnHelper } from "@tanstack/react-table";
import IconExternalLink from "../assets/icons/IconExternalLink";
import { Tooltip } from "react-tooltip";

const columnHelper = createColumnHelper();

export const retirementHistoryColumns = [
  columnHelper.accessor("date", {
    header: () => "Date",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("time", {
    header: () => "Time",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetCode", {
    header: () => "Asset Token",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registryName", {
    header: () => "Registry",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("inventoryId", {
    header: () => "Inventory Line",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("quantity", {
    header: () => "Amount Retired",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("startId", {
    header: () => "First ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("endId", {
    header: () => "Last ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("holder", {
    header: () => "Holder",
    cell: (props) => props.getValue() || "-",
  })
];

export const RetirementHistoryConfirmationColumns = [
  columnHelper.accessor("id", {
    header: () => "Inventory ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetCode", {
    header: () => "Asset Token",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetName", {
    header: () => "Asset Name",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registry", {
    header: () => "Registry",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registryProject", {
    header: () => "Registry Project",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("quantity", {
    header: () => "Number of COUs",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("startId", {
    header: () => "Start ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("endId", {
    header: () => "End ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("retiredQuantity", {
    header: () => "Retired Quantity",
    cell: (props) => (
      <span className="text-primary">{props.getValue() || "-"}</span>
    ),
  }),
  columnHelper.accessor("retiredStartId", {
    header: () => "Retired Start ID",
    cell: (props) => (
      <span className="text-primary">{props.getValue() || "-"}</span>
    ),
  }),
  columnHelper.accessor("retiredEndId", {
    header: () => "Retired End ID",
    cell: (props) => (
      <span className="text-primary">{props.getValue() || "-"}</span>
    ),
  }),
];