export const routes = {
  auth: "/login",
  listing: "/",
  documents: "/documents",
  holding: "/holding",
  listingDetail: "/instrument/:id",
  inventory: "/inventory",
  retirementRequests: "/retirement-requests",
  retirementHistory: "/retirement-history",
}

export default routes
