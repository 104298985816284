import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../shared/Layout";
import Table from "../widgets/Table";
import { retirementRequestColumns } from "../data/RetirementRequestTableData";
import Pagination from "../widgets/Pagination";
import PageTitle from "../widgets/PageTitle";
import { Bars } from "react-loader-spinner";
import { getRetirementRequests, getRegistryData } from "../services/InventoryService";
import DocumentSort from "../components/Sorts/DocumentSort";
import retirementRequestModel from "../models/RetirementRequestModel";
import SearchFilter from "../components/Filters/SearchFilter";
import RetirementRequestModal from "../components/RetirementRequestModal";
import { updatePage } from "../services/CommonService";

const rowsPerPage = 10;

const RetirementRequests = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [assetCode, setAssetCode] = useState(null);
  const [holder, setHolder] = useState(null);
  const [sortType, setSortType] = useState({ key: "date-created", order: "desc" });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [registryData, setRegistryData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [currentPage, assetCode, holder, sortType]);

  useEffect(() => {
    const fetchRegistryData = async () => {
      try {
        const data = await getRegistryData();
        setRegistryData(data);
      } catch (error) {
        console.error("Error fetching registry data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRegistryData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const startRow = currentPage * rowsPerPage;
      const retirementResponse = await getRetirementRequests(
        holder,
        rowsPerPage,
        startRow,
        assetCode,
        sortType
      );
      if (retirementResponse.status) {
        const retirementData = retirementRequestModel.parseList(retirementResponse.data || []);
        setData(retirementData);
        setTotalPages(Math.ceil(retirementResponse.totalRows / rowsPerPage));
      } else {
        setData([]);
        setErrorMsg(retirementResponse.message);
      }
    } catch (error) {
      setData([]);
      setErrorMsg("Failed to fetch retirement requests.");
    } finally {
      setLoading(false);
    }
  };

  const onFilterChange = (filterType, value) => {
    setCurrentPage(0);
    if (filterType === "holder") setHolder(value);
    if (filterType === "assetCode") setAssetCode(value);
  };

  const onSortChange = (sort) => {
    setCurrentPage(0);
    setSortType(sort);
  };

  const handleRowClick = (request) => {
    if (request.status === "Retired") {
      navigate(`/retirement-history?retirement-request-id=${request.id}`);
    } else {
      setSelectedRequest(request);
      setModalOpen(true);
    }
  };

  return (
    <Layout className="space-y-6">
      <div className="flex items-center justify-between">
        <PageTitle title="Retirement Requests" level={1} />
        <div className="flex gap-4">
          <SearchFilter
            label="Asset Code"
            placeholder="Type and press Enter..."
            onSearchChange={(value) => onFilterChange("assetCode", value)}
            mandatory={false}
          />
          <SearchFilter
            label="Holder"
            placeholder="Type and press Enter..."
            onSearchChange={(value) => onFilterChange("holder", value)}
            mandatory={false}
          />
          <DocumentSort
            label="Sort By"
            data={[
              { label: "Date Created", value: { key: "date-created", order: "desc" } },
              { label: "Quantity", value: { key: "quantity", order: "desc" } },
            ]}
            selectedValue={sortType}
            onFilterchange={(_, selectedOption) => onSortChange(selectedOption.value)}
          />
        </div>
      </div>
      {loading ? (
        <div className="text-center py-10 flex justify-center">
          <Bars height="80" width="80" color="rgb(78,183,63)" ariaLabel="bars-loading" />
        </div>
      ) : errorMsg ? (
        <div className="text-red-500">{errorMsg}</div>
      ) : (
        <Table 
          data={data} 
          columns={retirementRequestColumns(fetchData)} 
          interactable 
          onRowClick={handleRowClick} 
        />
      )}
      <RetirementRequestModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        retirementRequest={selectedRequest}
        registryData={registryData}
      />
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          onPageChange={(type) => setCurrentPage((prevPage) => updatePage(prevPage, totalPages, type))}
        />
      )}
    </Layout>
  );
};

export default RetirementRequests;