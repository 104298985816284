import React, { useEffect, useState } from "react";
import Button from "../widgets/Button";
import Table from "../widgets/Table";
import { getInventoryData, createRetirementHistory } from "../services/InventoryService";
import { fullfillmentColumns } from "../data/InventoryTableData";
import inventoryModel from "../models/InventoryModel";
import Pagination from "../widgets/Pagination";
import RetirementConfirmationModal from "./RetirementConfirmationModal";
import { updatePage } from "../services/CommonService";

const RetirementRequestModal = ({ isOpen, onClose, retirementRequest, registryData }) => {
  const [inventoryData, setInventoryData] = useState([]);
  const [pagesData, setPagesData] = useState({}); // store parsed data per page
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const [isConfirmationStep, setIsConfirmationStep] = useState(false);
  const [retirementData, setRetirementData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [allocatedPerPage, setAllocatedPerPage] = useState({});

  const rowsPerPage = 10; // constant for rows per page

  const getRegistryName = (registryCode) => {
    const matchedRegistry = registryData.find(
      (item) => item["registry-code"] === registryCode
    );
    return matchedRegistry ? matchedRegistry["registry-name"] : "Unknown Registry";
  };

  useEffect(() => {
    if (isOpen && retirementRequest && retirementRequest.status !== "Retired") {
      // Reset current page and pagesData when a new retirementRequest is opened.
      setCurrentPage(0);
      setPagesData({});
      setAllocatedPerPage({});
      fetchInventoryData(0);
    }
  }, [isOpen, retirementRequest]);

  const fetchInventoryData = async (page) => {
    setErrorMsg("");

    const { registry: registryCode, assetCode } = retirementRequest || {};
    const registryName = getRegistryName(registryCode);
    const startRow = page * rowsPerPage;

    try {
      const response = await getInventoryData(
        ["Processed", "Partially Retired"],
        registryName,
        rowsPerPage,
        startRow,
        assetCode,
        { key: "date", order: "asc" }
      );

      if (response.status) {
        let remainingRetirementQuantity = retirementRequest.quantity;
        // Parse the fulfillment lines for this page.
        const parsedData = inventoryModel.parseList(response.data || []).map((item) => {
          const availableToAllocate = item.quantity - (item.numberRetired || 0);
          let allocatedQuantity = Math.min(remainingRetirementQuantity, availableToAllocate);
          let retiredStartId = allocatedQuantity > 0 ? item.startId + (item.numberRetired || 0) : "";
          let retiredEndId = allocatedQuantity > 0 ? retiredStartId + allocatedQuantity - 1 : "";
          remainingRetirementQuantity -= allocatedQuantity;
          return {
            ...item,
            retiredQuantity: allocatedQuantity > 0 ? allocatedQuantity.toString() : "",
            retiredStartId: allocatedQuantity > 0 ? retiredStartId : "",
            retiredEndId: allocatedQuantity > 0 ? retiredEndId : "",
          };
        });

        // Store the data for this page.
        setInventoryData(parsedData);
        setPagesData((prev) => ({ ...prev, [page]: parsedData }));

        const originalTotalPages = Math.ceil(response.totalRows / rowsPerPage);
        // Compute the allocated amount on this page.
        const pageAllocated = parsedData.reduce(
          (sum, row) => sum + (row.retiredQuantity ? parseInt(row.retiredQuantity) : 0),
          0
        );
        setAllocatedPerPage((prev) => ({ ...prev, [page]: pageAllocated }));

        // Compute cumulative allocation from page 0 up to this page.
        const cumulativeAllocated = Object.values({ ...allocatedPerPage, [page]: pageAllocated })
          .reduce((sum, val) => sum + val, 0);

        // If cumulative allocation is enough, limit totalPages.
        const newTotalPages =
          cumulativeAllocated >= retirementRequest.quantity ? page + 1 : originalTotalPages;
        setTotalPages(newTotalPages);
      } else {
        setInventoryData([]);
        setErrorMsg(response.message);
      }
    } catch (error) {
      setInventoryData([]);
      setErrorMsg("Failed to fetch inventory data.");
    }
  };

  const hasValidationErrors = Object.values(validationErrors).some(
    (rowErrors) => Object.keys(rowErrors).length > 0
  );

  const hasValidInputs = Object.values(pagesData).flat().some(
    (row) => row.retiredQuantity && row.retiredStartId && row.retiredEndId
  );

  // This function ensures that we have data from pages 0 to totalPages-1.
  const fetchAllPagesData = async () => {
    let combinedData = [];
    for (let page = 0; page < totalPages; page++) {
      if (!pagesData[page]) {
        // If page data is missing, fetch it.
        await fetchInventoryData(page);
      }
      if (pagesData[page]) {
        combinedData = combinedData.concat(pagesData[page]);
      }
    }
    return combinedData;
  };

  const handleConfirmSave = async () => {
    try {
      for (const row of retirementData.filter((row) => row.retiredQuantity)) {
        await createRetirementHistory({
          retirementrequestid: retirementRequest.id,
          inventoryid: row.id,
          quantity: row.retiredQuantity || 0,
          startid: row.retiredStartId || null,
          endid: row.retiredEndId || null,
        });
      }
      onClose();
    } catch (error) {
      console.error("Error saving retirement history:", error);
      alert("Failed to save retirement history.");
    } finally {
      setIsConfirmationStep(false);
    }
  };

  // Modified handleSave to combine fulfillment lines from all pages.
  const handleSave = async () => {
    const combinedData = await fetchAllPagesData();
    const filteredData = combinedData.filter(
      (row) => row.retiredQuantity && row.retiredStartId && row.retiredEndId
    );

    if (filteredData.length === 0) {
      alert("No valid retirement entries to process.");
      return;
    }

    setRetirementData(filteredData);
    setIsConfirmationStep(true);
  };

  // When pagination button is pressed, update currentPage and fetch that page.
  const handlePageChange = (type) => {
    setCurrentPage((prevPage) => {
      const newPage = updatePage(prevPage, totalPages, type);
      if (newPage !== prevPage) {
        fetchInventoryData(newPage);
      }
      return newPage;
    })
  };

  if (!isOpen || !retirementRequest) return null;

  return (
    <>
      {!isConfirmationStep ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 w-auto min-w-[85vw] max-w-screen-lg overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">
              Retirement Request Details
            </h2>

            <div className="grid grid-cols-12 gap-6 mb-6">
              <div className="col-span-4 bg-gray-100 p-4 rounded-lg">
                <div className="grid grid-cols-4 gap-4">
                  <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-800">Asset Code</span>
                    <span className="text-base text-gray-600">
                      {retirementRequest.assetCode || "-"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-800">Registry</span>
                    <span className="text-base text-gray-600">
                      {getRegistryName(retirementRequest.registry)}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-800">Quantity</span>
                    <span className="text-base text-gray-600">
                      {retirementRequest.quantity || "-"}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base font-bold text-gray-800">Status</span>
                    <span className="text-base text-gray-600">
                      {retirementRequest.status || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {retirementRequest.status !== "Retired" && (
              <>
                <h2 className="text-xl font-semibold mb-4 text-gray-800">
                  Fulfillment Details
                </h2>

                <div className="overflow-x-auto">
                  <Table data={inventoryData} columns={fullfillmentColumns} className="mt-4" />
                </div>

                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPage={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </>
            )}

            <div className="flex justify-end space-x-4 mt-6">
              <Button title="Close" onClick={onClose} className="bg-gray/50" />
              <Button
                title="Fulfill"
                onClick={handleSave}
                className={`text-white py-2 px-6 rounded-md ${
                  !hasValidInputs || hasValidationErrors ? "opacity-50 cursor-not-allowed" : ""
                }`}
                isDisabled={!hasValidInputs || hasValidationErrors}
              />
            </div>
          </div>
        </div>
      ) : (
        <RetirementConfirmationModal
          isOpen={isConfirmationStep}
          retirementData={retirementData}
          onConfirm={handleConfirmSave}
          onCancel={() => setIsConfirmationStep(false)}
        />
      )}
    </>
  );
};

export default RetirementRequestModal;