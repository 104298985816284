import moment from "moment";

const inventoryModel = {
  parseList(data) {
    return data.map(item => ({
      id: item.id,
      date: moment(item['date-created']).format("D MMM YYYY"),
      time: moment(item['date-created']).format("HH:mm:ss"),
      registry: item['registry-name'] || "",
      registryProject: item['registry-project'] || "",
      registryRecord: item['registry-record'] || "",
      quantity: item.quantity || 0,
      startId: item['start-id'] || "",
      numberRetired: item['total-retired'] || 0,
      endId: item['end-id'] || "",
      assetCode: item['asset-code'] || "",
      assetName: item['asset-name'] || "",
      chain: item.chain || "",
      status: item.status || "",
      creationUrl: item['creation-url'] || "",
    }));
  },
};

export default inventoryModel;
