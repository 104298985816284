import React, { useEffect, useState } from "react";
import Layout from "../shared/Layout";
import Table from "../widgets/Table";
import { inventoryColumns } from "../data/InventoryTableData";
import Pagination from "../widgets/Pagination";
import PageTitle from "../widgets/PageTitle";
import { Bars } from "react-loader-spinner";
import { getInventoryData, updateInventoryItem, getRegistryData, getStatusData } from "../services/InventoryService";
import DropDownFilter from "../components/Filters/DropDownFilter";
import DocumentSort from "../components/Sorts/DocumentSort";
import inventoryModel from "../models/InventoryModel";
import EditInventoryModal from '../components/EditInventoryModal';
import SearchFilter from "../components/Filters/SearchFilter";
import { getRegistryFromToken, hasUserAccess, updatePage } from "../services/CommonService";
import { INVENTORY_POST_ROLE } from "../constants/roles";

const Inventory = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [assetCode, setAssetCode] = useState(null);
  const [status, setStatus] = useState(null);
  const [registry, setRegistry] = useState();
  const [sortType, setSortType] = useState({ key: "date-created", order: "desc" });
  const [statusNames, setStatusNames] = useState([]);
  const [registryNames, setRegistryNames] = useState([]);
  const [registryData, setRegistryData] = useState([]);

  const isAdmin = getRegistryFromToken().toLowerCase() === "admin";
  const statusData = getStatusData();

  useEffect(() => {
    const fetchRegistryData = async () => {
      try {
        const data = await getRegistryData();
        setRegistryData(data);
      } catch (error) {
        console.error("Error fetching registry data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRegistryData();
  }, []);

  useEffect(() => {
    if (registryData.length > 0) {
      try {
        const statusNames = Array.isArray(statusData) ? statusData.map((status) => status["status-name"]) : [];
        const registryNames = Array.isArray(registryData) ? registryData.map((registry) => registry["registry-name"]) : [];

        setStatusNames(statusNames);
        setRegistryNames(registryNames);
      } catch (error) {
        console.error("Error processing registry data:", error);
      }
    }
  }, [registryData]);

  useEffect(() => {
    if (!isAdmin && registryData.length > 0) {
      const registryCode = getRegistryFromToken();
      const matchedRegistry = registryData.find(item => item["registry-code"] === registryCode);

      if (matchedRegistry) {
        setRegistry(matchedRegistry["registry-name"]);
      }
    }
  }, [isAdmin, registryData]);

  useEffect(() => {
    fetchData();
  }, [currentPage, assetCode, status, registry, sortType]);

  const fetchData = async () => {
    setLoading(true);
    setErrorMsg("");
    try {
      const rowsPerPage = 10;
      const startRow = currentPage * rowsPerPage;
  
      const inventoryResponse = await getInventoryData(
        status ? [status] : [],
        registry,
        rowsPerPage,
        startRow,
        assetCode,
        sortType
      );
  
      if (inventoryResponse.status) {
        const inventoryData = inventoryModel.parseList(inventoryResponse.data || []);
        setData(inventoryData);
        setErrorMsg("");
        setTotalPages(Math.ceil(inventoryResponse.totalRows / rowsPerPage));
      } else {
        setData([]);
        setErrorMsg(inventoryResponse.message);
      }
    } catch (error) {
      setData([]);
      setErrorMsg("Failed to fetch inventory data.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };  
    
  const canEditInventory = () => {
    return hasUserAccess([INVENTORY_POST_ROLE]);
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const onFilterChange = (filterType, value) => {
    setCurrentPage(0);
    
    if (filterType === "status") setStatus(value);
    if (filterType === "registry") setRegistry(value);
    if (filterType === "assetCode") setAssetCode(value);
    
    fetchData();
  };      

  const onSortChange = (sort) => {
    setCurrentPage(0);
    setSortType(sort);
  };

  const handleSave = async (updatedItem) => {
    try {
      await updateInventoryItem(updatedItem);
      setData((prevData) =>
        prevData.map((item) => (item.id === updatedItem.id ? updatedItem : item))
      );
      setErrorMsg("");
      setIsModalOpen(false);
    } catch (error) {
      setErrorMsg("Failed to update inventory item.");
      console.error(error);
    }
  };

  const createOptions = (names) => names.map((name) => ({ label: name, value: name }));

  return (
    <Layout className="space-y-6">
      <div className="flex items-center justify-between">
        <PageTitle title="Inventory" level={1} />
        <div className="flex gap-4">
          <SearchFilter
            label="Asset Code"
            placeholder="Type and press Enter..."
            onSearchChange={(value) => onFilterChange("assetCode", value)}
          />
          <DropDownFilter
            label="Status"
            data={createOptions(statusNames)}
            selectedValue={status}
            onFilterChange={(value) => onFilterChange("status", value)}
          />
          {isAdmin && (
            <DropDownFilter
              label="Registry"
              data={createOptions(registryNames)}
              selectedValue={registry}
              onFilterChange={(value) => onFilterChange("registry", value)}
            />
          )}
          <DocumentSort
            label="Sort By"
            data={[
              { label: "Date Created", value: { key: "date-created", order: "desc" } },
              { label: "Status", value: { key: "status", order: "asc" } },
            ]}
            selectedValue={sortType}
            onFilterchange={(_, selectedOption) => onSortChange(selectedOption.value)}
          />
        </div>
      </div>
      {loading ? <Bars height="80" width="80" color="rgb(78,183,63)" /> : (
        <Table
          data={data}
          columns={inventoryColumns}
          interactable={canEditInventory()}
          onRowClick={handleRowClick}
        />
      )}
      {totalPages > 1 && <Pagination currentPage={currentPage} totalPage={totalPages}     onPageChange={(type) => setCurrentPage((prevPage) => updatePage(prevPage, totalPages, type))} />}
      <EditInventoryModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} inventoryItem={selectedItem} onSave={handleSave} />
    </Layout>
  );
};

export default Inventory;