import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams
import Layout from "../shared/Layout";
import Table from "../widgets/Table";
import Pagination from "../widgets/Pagination";
import PageTitle from "../widgets/PageTitle";
import { Bars } from "react-loader-spinner";
import { getRetirementHistory, getRegistryData } from "../services/InventoryService";
import { retirementHistoryColumns } from "../data/RetirementHistoryTableData";
import retirementHistoryModel from "../models/RetirementHistoryModel";
import DropDownFilter from "../components/Filters/DropDownFilter";
import SearchFilter from "../components/Filters/SearchFilter";
import { getRegistryFromToken } from "../services/CommonService";
import { updatePage } from "../services/CommonService";

const RetirementHistory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [assetCode, setAssetCode] = useState(null);
  const [holder, setHolder] = useState(null);
  const [registry, setRegistry] = useState();
  const [registryData, setRegistryData] = useState([]);
  const [registryNames, setRegistryNames] = useState([]);
  const [searchParams] = useSearchParams(); // Get query params from URL
  const isAdmin = getRegistryFromToken().toLowerCase() === "admin";

  // Get the `inventory-id` from the URL if it exists
  const inventoryId = searchParams.get("inventory-id") || "";
  // Get the `retirement-request-id` from the URL if it exists
  const retirementRequestId = searchParams.get("retirement-request-id") || "";

  useEffect(() => {
    fetchRegistryData();
  }, []);

  const fetchRegistryData = async () => {
    try {
      const data = await getRegistryData();
      setRegistryData(data);
    } catch (error) {
      console.error("Error fetching registry data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [registryData, currentPage, assetCode, holder, registry, inventoryId, retirementRequestId]);

  const fetchData = async () => {
    setLoading(true);
    setErrorMsg("");

    try {
      const rowsPerPage = 10;
      const startRow = currentPage * rowsPerPage;

      const response = await getRetirementHistory(
        registry,
        inventoryId,
        assetCode,
        holder,
        retirementRequestId,
        startRow,
        rowsPerPage
      );

      if (response.status) {
        const retirementData = retirementHistoryModel.parseList(response.data, registryData);
        setData(retirementData);
        setTotalPages(Math.ceil(response.totalRows / rowsPerPage));
      } else {
        setData([]);
        setErrorMsg(response.message);
      }
    } catch (error) {
      setData([]);
      setErrorMsg("Failed to fetch retirement history.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFilterChange = (filterType, value) => {
    setCurrentPage(0);

    if (filterType === "registry") setRegistry(value);
    if (filterType === "assetCode") setAssetCode(value);
    if (filterType === "holder") setHolder(value);

    fetchData();
  };

  const createOptions = (data) => data.map((item) => ({ label: item["registry-name"], value: item["registry-name"] }));

  return (
    <Layout className="space-y-6">
      <div className="flex items-center justify-between">
        <PageTitle title="Retirement History" level={1} />
        <div className="flex gap-4">
          <SearchFilter
            label="Asset Code"
            placeholder="Type and press Enter..."
            onSearchChange={(value) => onFilterChange("assetCode", value)}
          />
          <SearchFilter
            label="Holder"
            placeholder="Type and press Enter..."
            onSearchChange={(value) => onFilterChange("holder", value)}
          />
          {isAdmin && (
            <DropDownFilter
              label="Registry"
              data={createOptions(registryNames)}
              selectedValue={registry}
              onFilterChange={(value) => onFilterChange("registry", value)}
            />
          )}
        </div>
      </div>
      {loading ? <Bars height="80" width="80" color="rgb(78,183,63)" /> : <Table data={data} columns={retirementHistoryColumns} />}
      {totalPages > 1 && <Pagination currentPage={currentPage} totalPage={totalPages} onPageChange={(type) => setCurrentPage((prevPage) => updatePage(prevPage, totalPages, type))} />}
    </Layout>
  );
};

export default RetirementHistory;