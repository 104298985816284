import React, { useEffect } from "react";
import Image from "../widgets/Image";
import Logo from "../assets/images/secdex-logo.png";
import Favicon from "../assets/images/secdex-favicon.png";
import MetaDetails from "../widgets/MetaDetails";
import { useNavigate } from "react-router-dom";
import OktaSignInWidget from "../components/Okta/OktaSignInWidget";
import { jwtDecode } from "jwt-decode";

const Authentication = () => {
  const navigate = useNavigate();

  const authenticatedHandler = (authResult) => {
    const accessToken = authResult.accessToken;
    if (accessToken) {
      const tokenDetails = jwtDecode(accessToken);
      localStorage.setItem(
        "okta-token-storage",
        JSON.stringify({ accessToken, tokenDetails })
      );
      navigate("/", { replace: true });
    }
  };
  useEffect(() => {
    const auth0Lock = require("../helper/auth0").auth0Lock;
    auth0Lock.show();
    auth0Lock.on("authenticated", authenticatedHandler);
    return () => {
      auth0Lock.off("authenticated", authenticatedHandler);
    };
  }, []);

  return (
    <>
      <MetaDetails title="ZERO13" description="ZERO13" favicon={Favicon} />
      <div className={`relative h-screen bg-white flex-center`}>
        <div className="absolute top-0 left-0 h-2/4 w-full bg-welcomeBackgroundTopHalf" />
        <div className="relative container z-10 max-lg:mt-14 flex justify-center">
        <div className="grid lg:grid-cols-2 gap-10 lg:gap-12 2xl:gap-16 max-w-fit">
          {/* Left: Logo & Text */}
          <div className="relative text-left flex flex-col items-start justify-center h-full">
          <Image
            src={Logo}
            alt="SECDEX Logo"
            title="SECDEX"
            className="h-14 w-auto mt-10 object-contain"
          />
          <p className="text-sm 2xl:text-base text-white md:text-black font-quicksand leading-normal mt-6">
          Securities, Commodities and Derivatives Exchange (“SECDEX”) is a market infrastructure 
          ecosystem located in the Seychelles. The multi-asset hybrid exchange (“SECDEX Exchange”) 
          combines the benefits of a digital exchange with those of a traditional exchange. SECDEX 
          Exchange focuses on securities, debt, spot commodities, carbon credits and derivatives 
          contracts in traditional and digital form.
          </p>
        </div>

          {/* Right: Okta Sign-In Widget */}
          <div className="flex justify-left">
            <OktaSignInWidget />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Authentication;
