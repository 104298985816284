import axios from 'axios';
import { getAPIHeader } from "./CommonService";
import qs from 'qs';

export const getInventoryData = (
  status,
  registry,
  rows = 10,
  startRow = 0,
  assetCode = "",
  sortType = { key: "status", order: "asc" }
) => {
  return new Promise((resolve, reject) => {
    const params = {
      "sort-by": sortType.key,
      order: sortType.order,
      rows,
      "start-row": startRow,
    };

    const statusArray = status ? (Array.isArray(status) ? status : [status]) : null;

    if (registry) params.registry = registry;
    if (assetCode) params["asset-code"] = assetCode;
    if (status) params.status = statusArray;

    const url = window.env.INVENTORY_API_BASE_URI + "/data/registry/inventory";
    let headers = getAPIHeader();

    axios
      .get(url, { 
        params, 
        headers,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
      })
      .then((response) => {
        resolve({
          status: true,
          data: response.data.data,
          totalRows: response.data?.["page-details"]?.["total-rows"] || 0,
        });
      })
      .catch((error) => {
        resolve({
          status: false,
          message:
            error.response?.data?.error?.[0]?.msg ||
            "Failed to fetch inventory data.",
        });
      });
  });
};

export const updateInventoryItem = async (item) => {
  try {
    const url = `${window.env.INVENTORY_API_BASE_URI}/data/registry/inventory`; // Construct the URL

    let headers = getAPIHeader();

    const requestBody = {
      id: item.id,
      data: {
        "registry-project": item.registryProject,
        "registry-record": item.registryRecord,
        "start-id": item.startId,
        "end-id": item.endId,
        "asset-name": item.assetName,
      },
    };

    const response = await axios.put(url, requestBody, { headers });

    return response.data;
  } catch (error) {
    console.error("Error updating inventory item:", error.response?.data || error.message);
    throw error;
  }
};

export const getRegistryData = async () => {
  let headers = getAPIHeader();
  
  try {
    const response = await axios.get(`${window.env.INVENTORY_API_BASE_URI}/data/registry/registries`, { headers });

    if (response.status === 200 && response.data?.data) {
      return response.data.data;
    }

    console.warn("Unexpected API response structure for getRegistryData:", response.data);
    return [];
  } catch (error) {
    console.error("Error fetching registry data:", error.response?.data || error.message);
    return [];
  }
};

export const getStatusData = async () => {
      let headers = getAPIHeader();
      try {
        const response = await axios.get(`${window.env.INVENTORY_API_BASE_URI}/data/registry/statuses`, { headers });
        return response.data?.data || [];
      } catch (error) {
        console.error("Error fetching status data:", error);
        return [];
      }
};

export const getRetirementRequests = (
  holder,
  rows = 10,
  startRow = 0,
  assetCode = "",
  sortType = { key: "date-created", order: "desc" }
) => {
  return new Promise((resolve) => {
    const params = {
      "sort-by": sortType.key,
      order: sortType.order,
      rows,
      "start-row": startRow,
    };

    if (assetCode) params["asset-code"] = assetCode;
    if (holder) params["holder"] = holder;

    const url = window.env.INVENTORY_API_BASE_URI + "/data/registry/retirements";
    let headers = getAPIHeader();

    axios
      .get(url, {
        params,
        headers,
      })
      .then((response) => {
        resolve({
          status: true,
          data: response.data.data,
          totalRows: response.data?.["page-details"]?.["total-rows"] || 0,
        });
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.response?.data?.error?.[0]?.msg || "Failed to fetch retirement requests.",
        });
      });
  });
};

export const createRetirementHistory = async (item) => {
  const payload = {
    "retirement-request-id": item.retirementrequestid,
    "inventory-id": item.inventoryid,
    "quantity": Number(item.quantity),
    "start-id": Number(item.startid),
    "end-id": Number(item.endid),
  };

  const url = `${window.env.INVENTORY_API_BASE_URI}/data/registry/retirement-history`;
  const headers = getAPIHeader();

  try {
    const response = await axios.post(url, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error in createRetirementHistory:", error.response || error);
    throw error;
  }
};

export const getRetirementHistory = async (
  registry = "",
  inventoryId = "",
  assetCode = "",
  holder = "",
  retirementRequestId = "",
  startRow = 0,
  rows = 10
) => {
  try {
    const params = {
      "sort-by": "date-created",
      order: "desc",
      "start-row": startRow,
      rows,
    };

    if (registry) params.registry = registry;
    if (inventoryId) params["inventory-id"] = inventoryId;
    if (holder) params.holder = holder;
    if (assetCode) params["asset-code"] = assetCode;
    if (retirementRequestId) params["retirement-request-id"] = retirementRequestId;
    const url = `${window.env.INVENTORY_API_BASE_URI}/data/registry/retirement-history`;
    let headers = getAPIHeader();

    const response = await axios.get(url, { params, headers });

    return {
      status: true,
      data: response.data.data,
      totalRows: response.data?.["page-details"]?.["total-rows"] || 0,
    };
  } catch (error) {
    console.error("Error fetching retirement history:", error);
    return {
      status: false,
      message: error.response?.data?.error?.[0]?.msg || "Failed to fetch retirement history.",
    };
  }
};

/**
 * Create a certificate for a given retirement request.
 * @param {number} retirementRequestId - The ID of the retirement request to create a certificate for.
 * @returns {Promise<{status: boolean, data?: object, message?: string}>}
 */
export const createCertificate = async (retirementRequestId) => {
  try {
    const url = `${window.env.INVENTORY_API_BASE_URI}/data/registry/inventory-certificate`;
    const headers = getAPIHeader();

    const requestBody = {
      "retirement-request-id": retirementRequestId,
    };

    const response = await axios.put(url, requestBody, { headers });

    if (response.status === 200 && response.data?.data) {
      return {
        status: true,
        data: response.data.data,
      };
    }

    return {
      status: false,
      message: "Unexpected response structure when creating a certificate.",
    };
  } catch (error) {
    const errMsg =
      error?.response?.data?.error?.[0]?.msg ||
      error?.message ||
      "Failed to create certificate.";

    console.error("Error creating certificate:", error.response || error);

    return {
      status: false,
      message: errMsg,
    };
  }
};

/**
 * Fetch certificate documents for the given retirement request id.
 * @param {number|null} retirementRequestId - The retirement request ID to filter by (optional).
 * @param {Object} sortType - Object containing `key` (field to sort by) and `order` ('asc' or 'desc').
 * @param {number} startRow - Pagination start row (default 0).
 * @param {number} rows - Number of rows to retrieve (default 10).
 * @param {string|null} dateFrom - Start date for filtering (format: YYYY-MM-DD, optional).
 * @param {string|null} dateTo - End date for filtering (format: YYYY-MM-DD, optional).
 * @returns {Promise<{status: boolean, data?: any[], totalRows?: number, message?: string}>}
 */
export const getCertificateDocuments = async (
  retirementRequestId = null,
  sortType = { key: "date-created", order: "desc" },
  startRow = 0,
  rows = 10,
  dateFrom = null,
  dateTo = null
) => {
  try {
    const url = `${window.env.INVENTORY_API_BASE_URI}/data/registry/documents`;
    const headers = getAPIHeader();

    const params = {
      "sort-by": sortType.key,
      order: sortType.order,
      "start-row": startRow,
      rows,
    };

    // Include filters only if they are provided
    if (retirementRequestId) {
      params["retirement-request-id"] = retirementRequestId;
    }
    if (dateFrom) {
      params["date-from"] = dateFrom;
    }
    if (dateTo) {
      params["date-to"] = dateTo;
    }

    const response = await axios.get(url, { params, headers });

    return {
      status: true,
      data: response.data?.data || [],
      totalRows: response.data?.["page-details"]?.["total-rows"] || 0,
    };
  } catch (error) {
    console.error("Error fetching certificate documents:", error.response || error);
    return {
      status: false,
      message:
        error.response?.data?.error?.[0]?.msg ||
        "Failed to fetch certificate documents.",
    };
  }
};


/**
 * Download the certificate PDF for the given certificate ID.
 * @param {string|number} certificateId - The certificate's unique ID
 * @returns {Promise<{status: boolean, data?: ArrayBuffer, message?: string}>}
 */
export const getCertificateDocument = async (certificateId) => {
  try {
    const url = `${window.env.INVENTORY_API_BASE_URI}/data/registry/inventory-certificate/${certificateId}`;
    const headers = getAPIHeader();

    // Since the server returns JSON with shape { file: { data: [...], type: "Buffer" } },
    // do NOT use { responseType: "arraybuffer" }; we want to parse JSON.
    const response = await axios.get(url, { headers });

    // The PDF bytes are in response.data.file.data
    const byteArray = response.data?.file?.data;
    if (!byteArray) {
      // If there's no array, treat it as an error
      return {
        status: false,
        message: "Invalid PDF data returned by server (file.data not found).",
      };
    }

    // Convert numeric array to a typed array
    const typedArray = new Uint8Array(byteArray);

    return {
      status: true,
      data: typedArray, // The raw bytes of the PDF
    };
  } catch (error) {
    const errMsg =
      error?.response?.data?.error ||
      error?.message ||
      "Failed to fetch certificate document.";

    console.error("Error fetching certificate document:", error.response || error);

    return {
      status: false,
      message: errMsg,
    };
  }
};