import moment from "moment";
const certificateDocumentModel = {
    parseList(data) {
        let items = [];
        data.forEach((element) => {
            items.push({
                id: element.id,
                description: "retirement of " + element["asset-code"],
                download: "url",
                size: (element.size / 1024).toFixed(2) + " KB",
                date: moment(element["date-created"]).format("D MMM YYYY"),
                account: element.registry,
                symbol: element["asset-code"],
                type: "certificate",
            });
        });
        return items;
    },
};
export default certificateDocumentModel;