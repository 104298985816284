import React from "react";

const OktaSignInWidget = () => {
  return (
    <div className="md:relative md:h-full md:flex md:items-center">
      <div
        id="hiw-login-container"
        data-testid="hiw-login-container"
        className="shadow-2xl bg-white rounded-md md:mx-auto"
      />
    </div>
  );
};

export default OktaSignInWidget;
