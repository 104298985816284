import React from "react";
import Button from "../widgets/Button";
import Table from "../widgets/Table";
import { RetirementHistoryConfirmationColumns } from "../data/RetirementHistoryTableData";

const RetirementConfirmationModal = ({ isOpen, retirementData, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        className="bg-white rounded-lg shadow-xl p-4 overflow-y-auto flex flex-col"
        style={{
          maxHeight: "90vh",
          maxWidth: "fit-content",
          width: "auto",
        }}
      >
        <h3 className="text-lg font-semibold mb-3 text-gray-800 text-left">
          Confirm Retirement History
        </h3>

        <div className="overflow-x-auto border border-gray-300 rounded-lg">
          <Table data={retirementData} columns={RetirementHistoryConfirmationColumns} className="w-full" />
        </div>

        <div className="flex justify-end space-x-4 mt-4 p-2">
          <Button
            title="Cancel"
            onClick={onCancel}
            className="bg-gray-300 text-gray-800 hover:bg-gray-400 py-2 px-5 rounded-md"
          />
          <Button
            title="Confirm"
            onClick={onConfirm}
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-5 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default RetirementConfirmationModal;