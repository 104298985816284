import moment from "moment";

const retirementHistoryModel = {
  parseList(data, registryData) {
    return data.map((item) => {
      const matchedRegistry = registryData.find((r) => r["registry-code"] === item.registry);
      const registryName = matchedRegistry ? matchedRegistry["registry-name"] : "Unknown Registry";

      return {
        id: item.id,
        date: moment(item["date-created"]).format("D MMM YYYY"),
        time: moment(item["date-created"]).format("HH:mm:ss"),
        registry: item.registry,
        registryName,
        inventoryId: item["inventory-id"] || "",
        quantity: item.quantity || 0,
        startId: item["start-id"] || "",
        endId: item["end-id"] || "",
        assetCode: item["asset-code"] || "",
        holder: item.holder || "",
        certificateUrl: item["certificate-url"] || "",
      };
    });
  },
};

export default retirementHistoryModel;
