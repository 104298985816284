import { getExchangeName } from "./config";

const platforms = {
  SECDEX: {
    logo: "assets/branding/SECDEX/logo.png",
    favicon: "assets/branding/SECDEX/favicon.png",
    sidebarLabels: ["Listings", "Documents", "Holdings", "Inventory", "Retirement Requests", "Retirement History"],
    listingFilters: ["Segment", "Type"],
  },
  SDC: {
    logo: "assets/branding/SDC/logo.png",
    favicon: "assets/branding/SDC/favicon.png",
    sidebarLabels: ["Listings", "Documents", "Holdings", "Inventory", "Retirement Requests", "Retirement History"],
    listingFilters: ["Segment", "Type"],
    listingFilters: ["Segment", "Type"],
  },
  GMEX: {
    logo: "assets/branding/GMEX/logo.png",
    favicon: "assets/branding/GMEX/favicon.png",
    sidebarLabels: ["Listings", "Documents", "Holdings", "Inventory", "Retirement Requests", "Retirement History"],
    listingFilters: ["Segment", "Type"],
    listingFilters: ["Segment", "Type"],
  },
  GROW: {
    logo: "assets/branding/GROW/logo.png",
    favicon: "assets/branding/GROW/favicon.png",
    sidebarLabels: ["Projects", "Documents", "Holdings", "Inventory", "Retirement Requests", "Retirement History"],
    listingFilters: ["Continent", "Country"],
    listingFilters: ["Continent", "Country"],
  },
};

export const getExchangeConfig = () => {
  const exchange = getExchangeName();
  return {
    logo: platforms[exchange].logo,
    sidebarLabels: platforms[exchange].sidebarLabels,
    listingFilters: platforms[exchange].listingFilters,
    favicon: platforms[exchange].favicon,
    name: exchange,
  };
};

export const getAPIParamLimit = 100;