import React from "react";
import { Navigate } from "react-router-dom";
import { hasUserAccess } from "../services/CommonService";

const ProtectedRoute = ({ element, requiredRoles }) => {
  const hasAccess = hasUserAccess(requiredRoles);

  return hasAccess ? element : <Navigate to="/" replace />;
};

export default ProtectedRoute;
