import React from "react";
import Button from "../widgets/Button";

const EditInventoryConfirmationModal = ({ isOpen, editedFields, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  // Ensure Start ID is placed before End ID
  const orderedFields = Object.keys(editedFields).sort((a, b) => {
    if (a === "startId") return -1;
    if (b === "startId") return 1;
    if (a === "endId" && b !== "startId") return 1;
    if (b === "endId" && a !== "startId") return -1;
    return 0;
  });

  return (
    <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        className="modal-content bg-white rounded-lg shadow-xl p-6 w-[90vw] max-w-md overflow-y-auto"
        style={{
          maxHeight: "90vh",
          height: "auto",
        }}
      >
        <h3 className="text-xl font-semibold mb-4 text-gray-800">Confirm Changes</h3>
        <ul className="mb-6 space-y-3">
          {orderedFields.map((key) => (
            <li key={key} className="flex justify-between items-center">
              <span className="text-base font-bold text-gray-800 capitalize">
                {key === "startId" ? "Start ID" : key === "endId" ? "End ID" : key.replace(/([A-Z])/g, " $1")}
              </span>
              <span className="text-base text-gray-600">{editedFields[key]}</span>
            </li>
          ))}
        </ul>

        <div className="flex justify-end space-x-4">
          <Button
            title="Cancel"
            onClick={onCancel}
            className="bg-gray-300 text-gray-800 hover:bg-gray-400 py-2 px-6 rounded-md"
          />
          <Button
            title="Confirm"
            onClick={onConfirm}
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default EditInventoryConfirmationModal;
