import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { getCertificateDocument } from "../services/InventoryService"; // import your new function
import IconDownload from "../assets/icons/IconDownload";
import { Tooltip } from "react-tooltip";

const columnHelper = createColumnHelper();

export const docColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
  }),
  columnHelper.accessor("account", {
    header: () => "Account",
  }),
  columnHelper.accessor("symbol", {
    header: () => "Symbol",
  }),
  columnHelper.accessor("type", {
    header: () => "Type",
  }),
  columnHelper.accessor("description", {
    header: () => "Description",
  }),
  columnHelper.accessor("size", {
    header: () => "Size",
  }),
  columnHelper.accessor("date", {
    header: () => "Date",
  }),
  columnHelper.accessor("action", {
    header: () => "",
    cell: ({ row }) => {
      const docType = row.original.type;
      const docId = row.original.id;
      const downloadLink = row.original.download; // For normal docs

      async function handleDownloadCertificate() {
        try {
          const { status, data, message } = await getCertificateDocument(docId);
          if (!status) {
            console.error("Failed to fetch certificate document:", message);
            return;
          }
          // data is a Uint8Array of the PDF bytes
          const blob = new Blob([data], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, "_blank");
        } catch (err) {
          console.error("Error downloading certificate PDF:", err);
        }
      }

      // If "certificate", call the custom fetch and open as PDF
      if (docType === "certificate") {
        return (
          <>
            <Tooltip id="certificate-download" place="top" variant="light" size="sm" />
            <button
              type="button"
              onClick={handleDownloadCertificate}
              data-tooltip-id="certificate-download"
              data-tooltip-content="Download Certificate"
            >
              <IconDownload className="size-6" strokeWidth={2} />
            </button>
          </>
        );
      }

      // Otherwise use the normal link
      return (
        <>
          <Tooltip id="document-url" place="top" variant="light" size="sm" />
          <a
            href={downloadLink}
            rel="noreferrer"
            target="_blank"
            type="button"
            data-tooltip-id="document-url"
            data-tooltip-content="Download Document"
          >
            <IconDownload className="size-6" strokeWidth={2} />
          </a>
        </>
      );
    },
  }),
];

export const pubDocColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
  }),
  // columnHelper.accessor("symbol", {
  //   header: () => "Symbol",
  // }),
  columnHelper.accessor("type", {
    header: () => "Type",
  }),
  columnHelper.accessor("description", {
    header: () => "Description",
  }),
  columnHelper.accessor("size", {
    header: () => "Size",
  }),
  columnHelper.accessor("date", {
    header: () => "Date",
  }),
  columnHelper.accessor("action", {
    header: () => "",
    cell: (ss) => {
      return (
        <>
          <Tooltip id="document-url" place="top" variant="light" size="sm" />
          <a
            href={ss.row.original.download}
            rel="noreferrer"
            target="_blank"
            type="button"
          >
            <IconDownload
              className="size-6"
              strokeWidth={2}
              fileUrl={ss.row.original.download}
            />
          </a>
        </>
      );
    },
  }),
];

export const docData = [
  {
    id: "001",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "002",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "003",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "004",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "005",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "006",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "007",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "008",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
];
