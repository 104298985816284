import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import IconExternalLink from "../assets/icons/IconExternalLink";
import { Tooltip } from "react-tooltip";

const columnHelper = createColumnHelper();

export const inventoryColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("date", {
    header: () => "Date",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("time", {
    header: () => "Time",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetCode", {
    header: () => "Asset Code",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetName", {
    header: () => "Asset Name",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registry", {
    header: () => "Registry",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registryProject", {
    header: () => "Registry Project",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registryRecord", {
    header: () => "Registry Record",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("quantity", {
    header: () => "Number of COUs",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("startId", {
    header: () => "Start ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("endId", {
    header: () => "End ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("chain", {
    header: () => "Chain",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("creationUrl", {
    header: () => "Creation URL",
    cell: ({ row }) => {
      const url = row.original.creationUrl || "#";
      const tooltipId = `tooltip-creation-${row.original.id}`;
  
      return (
        <div className="flex items-center">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
            data-tooltip-id={tooltipId}
            onClick={(e) => e.stopPropagation()} // Prevent row click
          >
            <IconExternalLink className="size-6" strokeWidth={2} />
          </a>
          <Tooltip id={tooltipId} place="top" variant="light">
            Open Creation URL
          </Tooltip>
        </div>
      );
    },
  }),  
  columnHelper.accessor("numberRetired", {
    header: () => "Number Retired",
    cell: ({ row }) => {
      const retiredAmount = row.original.numberRetired || 0;
      const inventoryId = row.original.id;
      const tooltipId = `tooltip-retired-${inventoryId}`;
  
      return (
        <div className="flex items-center space-x-2">
          <span>{retiredAmount}</span>
          {retiredAmount > 0 && (
            <Link
              to={`/retirement-history?inventory-id=${inventoryId}`}
              className="flex items-center"
              data-tooltip-id={tooltipId}
              onClick={(e) => e.stopPropagation()} // Prevent row click
            >
              <IconExternalLink className="size-6" strokeWidth={2} />
            </Link>
          )}
          <Tooltip id={tooltipId} place="top" variant="light">
            Retirement History
          </Tooltip>
        </div>
      );
    },
  }),
  columnHelper.accessor("numberOutstanding", {
    header: () => "Number Outstanding",
    cell: (props) => {
      const quantity = props.row.original.quantity || 0;
      const numberRetired = props.row.original.numberRetired || 0;
      return quantity - numberRetired;
    },
  }),
  columnHelper.accessor("status", {
    header: () => "Status",
    cell: (props) => props.getValue() || "-",
  }),
];


export const fullfillmentColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetCode", {
    header: () => "Asset Code",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetName", {
    header: () => "Asset Name",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registry", {
    header: () => "Registry",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("quantity", {
    header: () => "Number of COUs",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("startId", {
    header: () => "Start ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("endId", {
    header: () => "End ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("numberRetired", {
    header: () => "Number Retired",
    cell: (props) => props.getValue() || "0",
  }),
  columnHelper.accessor("retiredQuantity", {
    header: () => "Quantity to Retire",
    cell: ({ row }) => (
      <span className="text-primary">
        {row.original.retiredQuantity || ""}
      </span>
    ),
  }),
  columnHelper.accessor("retiredStartId", {
    header: () => "Retired Start ID",
    cell: ({ row }) => (
      <span className="text-primary">
        {row.original.retiredStartId || ""}
      </span>
    ),
  }),
  columnHelper.accessor("retiredEndId", {
    header: () => "Retired End ID",
    cell: ({ row }) => (
      <span className="text-primary">
        {row.original.retiredEndId || ""}
      </span>
    ),
  }),
  columnHelper.accessor("creationUrl", {
    header: () => "Creation URL",
    cell: (props) => (
      <>
        <Tooltip id="creation-url" place="top" variant="light" size="sm" />
        <a href={props.getValue() || "#"} target="_blank" rel="noopener noreferrer">
          <IconExternalLink className="size-6" strokeWidth={2} />
        </a>
      </>
    ),
  }),
];