import React from "react";
import { Routes, Route } from "react-router-dom";
import Listings from "./pages/Listings";
import Documents from "./pages/Documents";
import Holding from "./pages/Holding";
import ListingDetail from "./pages/ListingDetail";
import Authentication from "./pages/Authentication";
import Inventory from "./pages/Inventory";
import { routes } from "./routes/routes";
import { Auth0Provider } from "@auth0/auth0-react";
import ProtectedRoute from "./components/ProtectedRoute";
import RetirementRequests from "./pages/RetirementRequests";
import RetirementHistory from "./pages/RetirementHistory";
import { INVENTORY_GET_ROLE } from "./constants/roles";

const App = () => {
  return (
    <Auth0Provider
      domain={window.env.REACT_APP_OKTA_HOST}
      clientId={window.env.REACT_APP_OKTA_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/login`,
        scope: "openid profile email",
      }}
    >
      <Routes>
        <Route path={routes.listing} element={<Listings />} />
        <Route path={routes.listingDetail} element={<ListingDetail />} />
        <Route path={routes.documents} element={<Documents />} />
        <Route path={routes.holding} element={<Holding />} />
        <Route path={routes.auth} element={<Authentication />} />
        <Route
          path={routes.inventory}
          element={<ProtectedRoute element={<Inventory />} requiredRoles={[INVENTORY_GET_ROLE]} />}
        />
        <Route
          path={routes.retirementHistory}
          element={<ProtectedRoute element={<RetirementHistory />} requiredRoles={[INVENTORY_GET_ROLE]} />}
        />
        <Route path={routes.retirementRequests} element={<RetirementRequests />} />
      </Routes>

    </Auth0Provider>
  );
};

export default App;
