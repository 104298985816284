import React, { useEffect, useState } from "react";
import Layout from "../shared/Layout";
import Table from "../widgets/Table";
import { holdColumns } from "../data/HoldingTableData";
import Pagination from "../widgets/Pagination";
import PageTitle from "../widgets/PageTitle";
import { getHoldings } from "../services/HoldingService";
import { Bars } from "react-loader-spinner";
import { useAtom } from "jotai";
import { accountsAtom, initLoadingAtom } from "../helper/store";
import HoldingsSort from "../components/Sorts/HoldingsSort";
import { getExchangeConfig } from "../helper/platformConfig";
import Search from "../components/Search";
import Checkbox from "../components/Checkbox";
import { updatePage } from "../services/CommonService";

const checkboxStates = {
  ACTIVE: "true",
  INACTIVE: "false",
};
const Holding = () => {
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [accounts] = useAtom(accountsAtom);
  const [initLoading] = useAtom(initLoadingAtom);
  const [sortType, setSortType] = useState(null);
  const [symbolSearch, setSymbolSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [showZeroBalance, setShowZeroBalance] = useState(
    checkboxStates.INACTIVE
  );
  const [accountSearch, setAccountSearch] = useState("")

  useEffect(() => {
    if (!initLoading) {
      setLoading(true);
      getHoldingsAPI(currentPage, sortType);
    }
  }, [
    currentPage,
    initLoading,
    sortType,
    nameSearch,
    symbolSearch,
    showZeroBalance,
    accountSearch
  ]);

  const getHoldingsAPI = (page, sortBy) => {
    const rowsPerPage = 10;
    
    getHoldings({
      page,
      accounts,
      sortBy,
      nameSearch,
      symbolSearch,
      showZeroBalance,
      accountSearch
    }).then((result) => {
      let holdingData = [];
      if (result.status) {
        holdingData = result.data;
        if (holdingData.length === 0) {
          setErrorMsg("No Data Available");
        }
        setTotalPages(Math.ceil(result.totalRows / rowsPerPage));
      } else {
        setErrorMsg(
          result.statusCode === 401
            ? "You are not authorised."
            : "No Data Available"
        );
      }
      setData(holdingData);
      setLoading(false);
    });
  };

  const onPageChange = (pagingType) => {
    if (loading) return;
    setCurrentPage((prevPage) => updatePage(prevPage, totalPages, pagingType));
  };  

  const onSortchange = (_, selectedOption) => {
    if (loading) {
      return;
    }
    setCurrentPage(0);
    setSortType(selectedOption.value);
  };

  return (
    <Layout className="space-y-6">
      <div className="relative lg:flex xl:flex items-center justify-between gap-6">
        <PageTitle title={getExchangeConfig().sidebarLabels[2]} level={1} />
        <div className="relative flex flex-col lg:flex-row items-center xl:justify-end lg:justify-end gap-6 mt-10 xl:mt-0 lg:mt-0">
          <Search
            value={accountSearch}
            onChange={(e) => {
              setCurrentPage(0);
              setAccountSearch(e.target.value);
            }}
            label="Account"
          />
          <Search
            value={nameSearch}
            onChange={(e) => {
              setCurrentPage(0);
              setNameSearch(e.target.value);
            }}
            label="Name"
          />
          <Search
            value={symbolSearch}
            onChange={(e) => {
              setCurrentPage(0);
              setSymbolSearch(e.target.value);
            }}
            label="Symbol"
          />
          <HoldingsSort
            data={[
              {
                label: "None",
                value: null,
              },
              {
                label: "Name",
                value: "asset.description",
              },
              {
                label: "Symbol (Desc)",
                value: "asset.code",
              },
            ]}
            onFilterchange={onSortchange}
          />
          <Checkbox
            selected={showZeroBalance === checkboxStates.ACTIVE}
            onChange={(e) => {
              if (e.target.checked) {
                setShowZeroBalance(checkboxStates.ACTIVE);
                setCurrentPage(0);
              } else {
                setShowZeroBalance(checkboxStates.INACTIVE);
                setCurrentPage(0);
              }
            }}
          />
        </div>
      </div>
      {loading && (
        <div className="text-center py-10 flex justify-center">
          <Bars
            height="80"
            width="80"
            color="rgb(78,183,63)"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-loading"
            visible={true}
          />
        </div>
      )}

      {!loading && data.length > 0 && (
        <Table columns={holdColumns} data={data} />
      )}
      {!loading && data.length === 0 && (
        <div className="text-center py-10">{errorMsg}</div>
      )}

      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </Layout>
  );
};

export default Holding;
